type FieldError = {
  message?: string;
};

interface InputTypes extends React.HTMLProps<HTMLInputElement> {
  register: any;
  placeholder: string;
  label: string;
  name: string;
  apply?: boolean;
  error?: FieldError;
  onChange?: (event: any) => void;
  value?: string;
  success?: string | undefined;
  prefix?: string;
  suffix?: any;
  disable?: boolean;
  applyFunc?: () => void;
  maxlength?: number;
  loading?: boolean;
  remove?: boolean;
  removeFunc?: () => void;
}

const TextInput: React.FC<InputTypes> = ({
  register,
  placeholder,
  label,
  name,
  apply = false,
  error,
  onChange,
  value = '',
  success,
  prefix,
  suffix,
  disable = false,
  applyFunc,
  maxlength,
  loading,
  remove = false,
  removeFunc,
  ...rest
}) => {
  const className =
    'h-12 w-full rounded-md bg-clip-padding p-3 text-gray-700 outline-none text-base';
  const errorStyle = error ? 'text-error' : '';
  let border = error ? ' border-2 border-primary' : ' border border-grey-dark';
  if (success) {
    border = ' border-2 border-success-copy ';
  }
  const successStyle = success ? 'text-success-copy' : '';

  let background = error
    ? 'url("/assets/images/svg/error.svg") no-repeat right 12px center'
    : '';
  if (success) {
    background =
      'url("/assets/images/svg/success.svg") no-repeat right 12px center';
  }

  const prefixClass = prefix && 'pl-6';

  const inputDisableClass = disable && ' pointer-events-none opacity-40';
  const labelDisableClass = disable && 'text-[rgba(0,0,0,.4)]';
  const right = success || error ? 'right-10' : 'right-6';
  const bottom = error ? 'bottom-1/2' : 'bottom-1/3';

  return (
    <>
      {success && (
        <div className="font-interMedium text-xs text-grey-dark">{success}</div>
      )}
      <div className="relative my-1 py-2 font-inter">
        <span className="absolute left-2 pt-3 text-grey-dark">{prefix}</span>
        <span className="absolute right-3 pt-3 text-grey-dark">{suffix}</span>
        <input
          {...register(name)}
          placeholder={placeholder}
          className={`${
            className + prefixClass
          } ${border} ${inputDisableClass} ${prefix && 'pl-5'}`}
          style={{
            background,
          }}
          onChange={onChange}
          defaultValue={value}
          maxLength={maxlength}
          {...rest}
        />
        {!loading && apply && (
          <button
            type="button"
            onClick={applyFunc}
            className={`absolute ${bottom} cursor-pointer font-interMedium text-sm text-attention ${right}`}
          >
            Apply
          </button>
        )}
        {!loading && remove && (
          <button
            type="button"
            onClick={removeFunc}
            className={`absolute ${bottom} cursor-pointer font-interMedium text-sm text-attention ${right}`}
          >
            Remove
          </button>
        )}
        <span
          className={`absolute top-0 left-4 bg-white px-2 text-xs ${errorStyle} ${successStyle} ${labelDisableClass}`}
        >
          {label}
        </span>
        {error && (
          <div className="pt-1 text-left text-sm leading-[18px] text-error">
            {error?.message}
          </div>
        )}
      </div>
    </>
  );
};

export default TextInput;
